import styled from 'styled-components';
import { Input } from 'antd';

import { COLORS } from '../../constants';

const { GREY_45 } = COLORS;

const AsnInput = styled(Input)`
  &.ant-input {
    background-color: transparent;
    border-radius: 0;
    /* border-top: 1px solid ${GREY_45}; */
  }
`;

export default AsnInput;

import React from 'react';
import { Flex, Typography, Image, Row, Col } from 'antd';

const { Paragraph } = Typography;
const width = window.innerWidth;

const ThirdGuide: React.FC = () => {
  return (
    <div style={width > 667 ? { padding: '10px 0px 0px 50px' } : { padding: '0px 16px' }}>
      <Row style={{ width: '100%' }} gutter={[0, 16]}>
        <Col
          xxl={{ span: 10, pull: 0, push: 0 }}
          xl={{ span: 10, pull: 0, push: 0 }}
          lg={{ span: 10, pull: 0, push: 0 }}
          md={{ span: 10, pull: 0, push: 0 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <div>
            <Image
              src={width > 667 ? './assets/guide3.png' : './assets/guide3mob.png'}
              preview={false}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'cover' }}
            />
          </div>
        </Col>
        <Col
          xxl={{ span: 10, pull: 1, push: 1 }}
          xl={{ span: 10, pull: 1, push: 1 }}
          lg={{ span: 10, pull: 1, push: 1 }}
          md={{ span: 10, pull: 1, push: 1 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <Flex align='center' style={{ height: '100%' }}>
            <Paragraph style={{ fontSize: width > 667 ? 16 : 20 }}>
              Discover and refine essential information seamlessly with our interactive map feature. Effortlessly locate
              specialists, lawyers, therapies, and more based on your destination or preferred state. Tailor your search
              further by filtering distance, extending up to 100 miles, and selecting specific categories. Empowering
              caregivers to navigate the intricate realm of special needs care has never been more intuitive and
              user-friendly.
            </Paragraph>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default ThirdGuide;

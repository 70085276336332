import React from 'react';
import { Modal, Typography, QRCode, Flex } from 'antd';
import { isAndroid, isIOS, isWindows, isMacOs } from 'react-device-detect';

import { IQrModal } from '../../types/components';
const { Title } = Typography;

const QrModal: React.FC<IQrModal> = ({ open, setOpenQr }) => {
  const getDeviceType = (): string => {
    if (isAndroid) return 'android';
    if (isIOS) return 'ios';
    if (isWindows) return 'windows';
    if (isMacOs) return 'macos';
    return 'other';
  };
  const qrUrl =
    getDeviceType() === 'windows' || getDeviceType() === 'android'
      ? 'https://play.google.com/store/apps/details?id=com.special.together'
      : 'https://apps.apple.com/am/app/special-together/id6504679103';

  return (
    <Modal open={open} footer={false} onCancel={(): void => setOpenQr(false)} centered>
      <Flex align='center' vertical>
        <Title level={3}>Scan QR Code to Download</Title>
        <QRCode value={qrUrl} />
      </Flex>
    </Modal>
  );
};

export default QrModal;

import { createBrowserRouter } from 'react-router-dom';

import { PATHS } from '../constants';
import Landing from '../pages/Landing';

export const routers = createBrowserRouter([
  {
    path: PATHS.LANDING,
    element: <Landing />,
  },
]);

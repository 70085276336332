export const PATHS = {
  LANDING: '/',
};

export const COLORS = {
  BRAND_COLOR: '#4E86AB',
  ORANGE: '#FCA545',
  BLACK: '#000000',
  GREY_200: '#121212',
  GREY_150: '#2B2B2B',
  GREY_100: '#5B5B5B',
  GREY_50: '#868686',
  GREY_45: '#F1F1F1',
  BLUE_150: '#1B265C',
  BLUE_50: '#4E86AB',
  WHITE: '#FFFFFF',
};

export const LINEAR_GRADIENTS = {
  PRIMARY: 'linear-gradient(146deg, #FBAA51 14.2%, #F78388 52.19%, #E28BB0 86.42%)',
  HOVER:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(146deg, #FBAA51 14.2%, #F78388 52.19%, #E28BB0 86.42%)',
  PRESSED:
    ' linear-gradient(0deg, rgba(114, 114, 114, 0.20) 0%, rgba(114, 114, 114, 0.20) 100%), linear-gradient(146deg, #FBAA51 14.2%, #F78388 52.19%, #E28BB0 86.42%)',
};

import React, { useState } from 'react';
import { Flex, Typography, Form, Row, Col } from 'antd';

import { COLORS } from '../../constants';
import { AsnButton, AsnInput, QrModal } from '../../components';
import { ReactComponent as QrSvg } from '../../assets/icons/qr.svg';
import { ReactComponent as AppleSvg } from '../../assets/icons/apple.svg';
import { ReactComponent as PlayMarketSvg } from '../../assets/icons/play.svg';

const { Paragraph, Link, Title } = Typography;
const { BLUE_150 } = COLORS;
const width = window.innerWidth;

const ContactUs: React.FC = () => {
  const [openQr, setOpenQr] = useState<boolean>(false);

  return (
    <>
      <div style={width > 667 ? { padding: '95px  50px' } : { padding: '30px 16px' }}>
        <Title style={{ fontSize: width > 667 ? 32 : 20, color: `${BLUE_150}`, marginBottom: 16, lineHeight: 1.5 }}>
          Contact Us
        </Title>
        <Row style={{ width: '100%' }}>
          <Col
            xxl={{ span: 10, pull: 0, push: 0 }}
            xl={{ span: 10, pull: 0, push: 0 }}
            lg={{ span: 10, pull: 0, push: 0 }}
            md={{ span: 9, pull: 0, push: 0 }}
            sm={{ span: 24, pull: 0, push: 0 }}
            xs={{ span: 24, pull: 0, push: 0 }}
          >
            {width <= 768 && (
              <>
                <Paragraph style={{ fontSize: width > 667 ? 16 : 20, marginBottom: 0 }}>
                  If you have any questions about the Special & Together application, You can contact us: By submitting
                  a message on our website:{' '}
                  <Link
                    href=' https://www.specialandtogether.com/'
                    target='_blank'
                    style={{ fontSize: width > 667 ? 16 : 20 }}
                  >
                    https://www.specialandtogether.com/
                  </Link>
                </Paragraph>
                <Paragraph style={{ fontSize: width > 667 ? 16 : 20, marginBottom: 32 }}>
                  By emailing us at info@specialandtogether.com
                </Paragraph>
              </>
            )}
            <Form layout='vertical'>
              <Form.Item label='Name'>
                <AsnInput />
              </Form.Item>
              <Form.Item label='E-mail'>
                <AsnInput />
              </Form.Item>
              <Form.Item label='Message'>
                <AsnInput />
              </Form.Item>
              <AsnButton type='primary' className='primary_btn' style={{ width: '100%' }}>
                SEND MESSAGE
              </AsnButton>
            </Form>
          </Col>
          <Col
            xxl={{ span: 10, pull: 0, push: 4 }}
            xl={{ span: 10, pull: 0, push: 4 }}
            lg={{ span: 12, pull: 0, push: 2 }}
            md={{ span: 14, pull: 0, push: 1 }}
            sm={{ span: 24, pull: 0, push: 0 }}
            xs={{ span: 24, pull: 0, push: 0 }}
          >
            <Flex vertical style={{ height: '100%' }} justify='end'>
              {width > 768 && (
                <>
                  <Paragraph style={{ fontSize: width > 667 ? 16 : 20, marginBottom: 0 }}>
                    If you have any questions about the Special & Together application, You can contact us: By
                    submitting a message on our website:{' '}
                    <Link href=' https://www.specialandtogether.com/' target='_blank'>
                      https://www.specialandtogether.com/
                    </Link>
                  </Paragraph>
                  <Paragraph style={{ fontSize: width > 667 ? 16 : 20, marginBottom: 32 }}>
                    By emailing us at info@specialandtogether.com ( ROSSLYN INTERNATIONAL LLC )
                  </Paragraph>
                </>
              )}
              <Flex
                gap={32}
                align='center'
                justify={width > 768 ? 'start' : 'center'}
                style={{ marginTop: width > 768 ? 0 : 32 }}
              >
                <div style={{ cursor: 'pointer' }}>
                  <AppleSvg
                    style={{ width: '100%' }}
                    onClick={() => window.open('https://apps.apple.com/am/app/special-together/id6504679103')}
                  />
                </div>
                <div style={{ cursor: 'pointer' }}>
                  <PlayMarketSvg
                    style={{ width: '100%' }}
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.special.together')}
                  />
                </div>
                {width > 768 && (
                  <AsnButton
                    type='primary'
                    shape='circle'
                    icon={<QrSvg />}
                    className='qr'
                    onClick={(): void => setOpenQr(true)}
                  />
                )}
              </Flex>
            </Flex>
          </Col>
        </Row>
      </div>
      <QrModal open={openQr} setOpenQr={setOpenQr} />
    </>
  );
};

export default ContactUs;

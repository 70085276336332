import React from 'react';
import styled from 'styled-components';
import { Flex, Typography, Row, Col } from 'antd';

import { COLORS } from '../../constants';
import { ReactComponent as LogoSvg } from '../../assets/icons/logo.svg';

const { Title, Paragraph } = Typography;
const { BLUE_150 } = COLORS;
const width = window.innerWidth;

const AsnTitle = styled(Title)`
  &.ant-typography {
    font-size: 24px;
    letter-spacing: 1.92px;
    margin-bottom: 0;
    text-align: start;
  }
`;

const Help: React.FC = () => {
  return (
    <div style={width > 667 ? { padding: '95px 50px 0px 50px' } : { padding: '30px 16px' }}>
      <Row style={{ width: '100%' }} gutter={[0, 16]}>
        <Col
          xxl={{ span: 10, pull: 0, push: 0 }}
          xl={{ span: 10, pull: 0, push: 0 }}
          lg={{ span: 10, pull: 0, push: 0 }}
          md={{ span: 10, pull: 0, push: 0 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <Title style={{ fontSize: width > 667 ? 32 : 22, color: `${BLUE_150}`, marginBottom: 32, lineHeight: 1.5 }}>
            Why Special & Together?
          </Title>
          <Paragraph style={{ fontSize: width > 667 ? 16 : 20 }}>
            Special & Together offers a holistic approach to finding nearby services and resources essential for
            individuals with special needs. It provides aggregated data on specialists, lawyers, therapies, financial
            organizations, and more. Furthermore, it offers terminology explanations, gives detailed and verified
            information about state government services, and keeps users informed about the latest developments in the
            field.
          </Paragraph>
        </Col>
        <Col
          xxl={{ span: 10, pull: 0, push: 4 }}
          xl={{ span: 10, pull: 0, push: 4 }}
          lg={{ span: 12, pull: 0, push: 2 }}
          md={{ span: 12, pull: 0, push: 2 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <Flex gap={12} align='center' style={{ height: '100%' }}>
            <LogoSvg style={width > 667 ? { width: 141, height: 136 } : { width: 42, height: 37 }} />
            <AsnTitle style={width > 667 ? { fontSize: 24, lineHeight: 1.5 } : { fontSize: 14, lineHeight: 1.5 }}>
              Special & Together
            </AsnTitle>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default Help;

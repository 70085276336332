import React from 'react';
import { Flex, Typography, Image, Row, Col } from 'antd';

import { ReactComponent as CheckSvg } from '../../assets/icons/check.svg';
const { Paragraph } = Typography;
const width = window.innerWidth;

const SecondGuide: React.FC = () => {
  return (
    <>
      <Row style={width > 667 ? { padding: '10px 0px 0px 50px' } : { padding: '0px 16px' }} gutter={[0, 16]}>
        <Col
          xxl={{ span: 10, pull: 0, push: 0 }}
          xl={{ span: 10, pull: 0, push: 0 }}
          lg={{ span: 10, pull: 0, push: 0 }}
          md={{ span: 10, pull: 0, push: 0 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
          order={width > 667 ? 0 : 1}
        >
          <Flex vertical style={{ height: '100%' }} justify='center' gap={12}>
            <Flex align='start' gap={24} style={{ paddingLeft: 36 }}>
              <Paragraph style={{ marginBottom: 0, fontSize: width > 667 ? 16 : 20 }}>
                Our comprehensive solution goes beyond being a repository of information. That&apos;s why we&apos;re
                excited to introduce two crucial features:
              </Paragraph>
            </Flex>
            <Flex align='start' gap={24}>
              <div style={{ marginTop: 6 }}>
                <CheckSvg />
              </div>
              <Paragraph style={{ marginBottom: 0, fontSize: width > 667 ? 16 : 20 }}>
                User-to-User Chat: Our platform includes a secure and user-friendly chat system that allows caregivers
                to connect with one another. It is a space for real-time conversations, support, and sharing valuable
                insights.
              </Paragraph>
            </Flex>
            <Flex align='start' gap={24}>
              <div style={{ marginTop: 6 }}>
                <CheckSvg />
              </div>
              <Paragraph style={{ marginBottom: 0, fontSize: width > 667 ? 16 : 20 }}>
                Discussion Forums: Our application hosts discussion forums dedicated to various topics and issues
                related to special needs care. These forums are moderated by experts and provide a safe space for users
                to engage in meaningful discussions, ask questions, and exchange advice. It is a valuable resource where
                parents can find answers and emotional support.
              </Paragraph>
            </Flex>
          </Flex>
        </Col>
        <Col
          xxl={{ span: 10, pull: 0, push: 1 }}
          xl={{ span: 10, pull: 0, push: 1 }}
          lg={{ span: 10, pull: 0, push: 1 }}
          md={{ span: 10, pull: 0, push: 1 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <div>
            <Image
              src={width > 667 ? './assets/guide2.png' : './assets/guide2mob.png'}
              preview={false}
              width={'100%'}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SecondGuide;

import React, { useState } from 'react';
import { Flex, Typography, Image, Row, Col } from 'antd';

import { COLORS } from '../../constants';
import { AsnButton, QrModal } from '../../components';
import { ReactComponent as QrSvg } from '../../assets/icons/qr.svg';
import { ReactComponent as AppleSvg } from '../../assets/icons/apple.svg';
import { ReactComponent as PlayMarketSvg } from '../../assets/icons/play.svg';

const { Title, Paragraph } = Typography;
const { BLUE_150 } = COLORS;

const width = window.innerWidth;

const Welcome: React.FC = () => {
  const [openQr, setOpenQr] = useState<boolean>(false);
  return (
    <>
      <Row gutter={[0, 32]} style={{ width: '100%' }}>
        <Col
          xxl={{ span: 12, pull: 0, push: 0 }}
          xl={{ span: 11, pull: 0, push: 0 }}
          lg={{ span: 18, pull: 3, push: 3 }}
          md={{ span: 18, pull: 3, push: 3 }}
          sm={{ span: 20, pull: 2, push: 2 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <Flex
            vertical
            style={width > 667 ? { paddingLeft: '50px', paddingTop: '95px' } : { padding: '30px 16px 0px 16px' }}
            align={width > 667 ? 'baseline' : 'center'}
          >
            <Title
              style={{
                fontSize: width > 667 ? 32 : 22,
                color: `${BLUE_150}`,
                marginBottom: width > 667 ? 30 : 16,
                textAlign: width > 667 ? 'start' : 'center',
                lineHeight: 1.5,
              }}
            >
              Welcome to a Special Place <br />
              where information and resources unite. <br />
              You&apos;re never alone here
            </Title>
            <Paragraph
              style={{
                fontSize: width > 667 ? 16 : 20,
                marginBottom: width > 667 ? 64 : 16,
                textAlign: width > 667 ? 'start' : 'center',
              }}
            >
              We are happy to introduce the Special & Together application. This is a one-of-a-kind social app designed
              to help individuals with disabilities, their parents, guardians, and caregivers. Navigating the complex
              landscape of special needs care, understanding terminology, and accessing crucial services can be
              overwhelming. They often don&apos;t know where to go to find a needed specialist, and even when they have
              details about a doctor or a specialist, they cannot be sure if the located information is accurate and
              up-to-date. Special & Together offers a comprehensive solution to these challenges.
            </Paragraph>
            <Flex gap={32} align='center'>
              <div style={{ cursor: 'pointer' }}>
                <AppleSvg
                  style={{ width: '100%' }}
                  onClick={() => window.open('https://apps.apple.com/am/app/special-together/id6504679103')}
                />
              </div>
              <div style={{ cursor: 'pointer' }}>
                <PlayMarketSvg
                  style={{ width: '100%' }}
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=com.special.together')}
                />
              </div>
              {width > 667 && (
                <AsnButton
                  type='primary'
                  shape='circle'
                  icon={<QrSvg />}
                  className='qr'
                  onClick={(): void => setOpenQr(true)}
                />
              )}
            </Flex>
          </Flex>
        </Col>
        <Col
          xxl={{ span: 12, pull: 0, push: 0 }}
          xl={{ span: 13, pull: 0, push: 0 }}
          lg={{ span: 18, pull: 3, push: 3 }}
          md={{ span: 18, pull: 3, push: 3 }}
          sm={{ span: 20, pull: 2, push: 2 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <Image
            src={width > 667 ? './assets/special.png' : './assets/specialmob.png'}
            preview={false}
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'cover', maxHeight: 550, maxWidth: '100%' }}
          />
        </Col>
      </Row>
      {openQr && <QrModal open={openQr} setOpenQr={setOpenQr} />}
    </>
  );
};

export default Welcome;

import React from 'react';

import { MainLayout } from '../../components';

import Welcome from './Welcome';
import FirstGuide from './FirstGuide';
import SecondGuide from './SecondGuide';
import ThirdGuide from './ThirdGuide';
import Help from './Help';
import ContactUs from './ContactUs';

const Landing: React.FC = () => {
  return (
    <MainLayout>
      <Welcome />
      <FirstGuide />
      <SecondGuide />
      <ThirdGuide />
      <Help />
      <ContactUs />
    </MainLayout>
  );
};
export default Landing;

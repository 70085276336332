import styled from 'styled-components';
import { Button } from 'antd';

import { COLORS, LINEAR_GRADIENTS } from '../../constants';

const { PRIMARY, HOVER, PRESSED } = LINEAR_GRADIENTS;
const { GREY_100, WHITE, GREY_50, GREY_150 } = COLORS;

const AsnButton = styled(Button)`
  &.qr {
    &.ant-btn {
      height: 58px;
      width: 58px;
      padding: 14px 15px;
    }
  }
  &.primary_btn {
    &.ant-btn {
      height: 48px;
      border-radius: 4px;
      font-size: 16px;
    }
  }
  &.heart {
    &.ant-btn {
      height: 35px;
      padding: 8px 16px;
      display: flex;
      background: ${PRIMARY};
      &:hover {
        background: ${HOVER};
      }
      &:active {
        background: ${PRESSED};
      }
    }
  }
  &.text_btn {
    &.ant-btn-text {
      font-size: 16px;
      color: ${GREY_100};
      font-weight: 400;
      padding: 4px 15px;
      &:hover {
        background: ${WHITE};
        color: ${GREY_50};
      }
      &:active {
        background: ${WHITE};
        color: ${GREY_150};
      }
    }
  }
`;

export default AsnButton;

import React, { useState } from 'react';
import { Layout, Typography, Flex } from 'antd';
import styled from 'styled-components';

import { IMainLayout } from '../../../types/components';
import { COLORS } from '../../../constants';
import { ReactComponent as LogoSvg } from '../../../assets/icons/logo.svg';
// import { ReactComponent as HeartSvg } from '../../../assets/icons/heart.svg';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as SpecialBtnSvg } from '../../../assets/icons/special_btn.svg';
import AsnButton from '../../Button';
const { Header, Content, Footer } = Layout;
const { WHITE, GREY_100 } = COLORS;
const { Title, Link, Text } = Typography;

const width = window.innerWidth;

const AsnHeader = styled(Header)`
  background: ${WHITE};
  backdrop-filter: blur(15px);
  border-bottom: 1px solid ${WHITE};
  height: auto;
  position: sticky;
  width: 100%;
  z-index: 1;
  top: 0;
  &.ant-layout-header {
    line-height: unset;
  }
`;

const AsnTitle = styled(Title)`
  &.ant-typography {
    letter-spacing: 1.44px;
    margin-bottom: 0;
  }
`;

const AsnContent = styled(Content)`
  background-image: url(./assets/background.png);
  background-size: 100% 100%;
`;

const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  const [header, setHeader] = useState<boolean>(true);
  return (
    <Layout>
      <AsnHeader style={{ padding: width > 667 ? '12px 50px' : '12px 16px' }}>
        {width <= 667 && header && (
          <Flex style={{ paddingBottom: '8px' }} align='center' gap={24}>
            <div onClick={(): void => setHeader(false)}>
              <CloseSvg />
            </div>
            <div>
              <SpecialBtnSvg />
            </div>
            <Title style={{ fontSize: 16 }}>Special & Together</Title>
          </Flex>
        )}
        <Flex justify='space-between' style={{ height: '100%', alignItems: 'center' }}>
          <Flex gap={12} align='center'>
            <LogoSvg />
            <AsnTitle style={{ fontSize: width > 667 ? 24 : 16 }}>Special & Together</AsnTitle>
          </Flex>
          <AsnButton>
            <Link
              href='https://www.gofundme.com/f/support-special-and-together-empower-families?attribution_id=sl:b56eb258-206c-4063-9979-12c8d9cd3798&utm_campaign=man_sharesheet_dash&utm_medium=customer&utm_source=website_widget'
              target='_blank'
              rel='noreferrer'
            >
              Donate now
            </Link>
          </AsnButton>
        </Flex>
      </AsnHeader>
      <AsnContent>{children}</AsnContent>
      <Footer style={{ background: `${WHITE}`, padding: width > 667 ? '24px 50px' : '16px' }}>
        <Flex justify='space-between' align='center'>
          <Flex gap={12} align='center'>
            <LogoSvg />
            {width > 875 && <AsnTitle style={{ fontSize: 24 }}>Special & Together</AsnTitle>}
          </Flex>
          <Text style={{ fontSize: 16, color: `${GREY_100}` }}>Created by` ROSSLYN INTERNATIONAL LLC</Text>
          <Flex gap={16}>
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://apispecialapp.analysed.ai/files/terms-and-conditions.pdf'
              style={{ fontSize: 16, color: `${GREY_100}` }}
            >
              Privacy Policy
            </Link>
            {/* <AsnButton type='primary' icon={<HeartSvg />} className='heart'>
              Donate
            </AsnButton> */}
          </Flex>
        </Flex>
      </Footer>
    </Layout>
  );
};

export default MainLayout;

import React from 'react';
import { Flex, Typography, Image, Row, Col } from 'antd';

import { COLORS } from '../../constants';

const { Title, Paragraph } = Typography;
const { BLUE_150 } = COLORS;

const width = window.innerWidth;

const FirstGuide: React.FC = () => {
  return (
    <div style={width > 667 ? { padding: '95px 0px 0px 50px' } : { padding: '30px 16px 0px 16px' }}>
      <Title style={{ fontSize: width > 667 ? 32 : 22, color: `${BLUE_150}`, marginBottom: 0, lineHeight: 1.5 }}>
        Some features you can find here
      </Title>
      <Row style={{ width: '100%' }} gutter={[0, 16]}>
        <Col
          xxl={{ span: 10, pull: 0, push: 0 }}
          xl={{ span: 10, pull: 0, push: 0 }}
          lg={{ span: 10, pull: 0, push: 0 }}
          md={{ span: 10, pull: 0, push: 0 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <div>
            <Image
              src={width > 667 ? './assets/instruction1.png' : './assets/instruction1mob.png'}
              preview={false}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'cover' }}
            />
          </div>
        </Col>
        <Col
          xxl={{ span: 10, pull: 1, push: 1 }}
          xl={{ span: 10, pull: 1, push: 1 }}
          lg={{ span: 10, pull: 1, push: 1 }}
          md={{ span: 10, pull: 1, push: 1 }}
          sm={{ span: 24, pull: 0, push: 0 }}
          xs={{ span: 24, pull: 0, push: 0 }}
        >
          <Flex align='center' style={{ height: '100%' }}>
            <Paragraph style={{ fontSize: width > 667 ? 16 : 20 }}>
              Our mobile application brings together all existing verified data about doctors, specialists, educational
              institutions, and therapies nearby that specialize in treating Special needs in children. It also includes
              information about Lawyers, Attorneys, and organizations that provide Financial, Assisted Living, and Care
              services.{' '}
            </Paragraph>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default FirstGuide;

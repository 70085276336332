import styled from 'styled-components';
import { Collapse } from 'antd';

import { COLORS } from '../../constants';

const { BLUE_150 } = COLORS;

const AsnCollapse = styled(Collapse)`
  .ant-collapse-header-text {
    font-size: 16px;
    letter-spacing: 1.28px;
    font-weight: 600;
    color: ${BLUE_150};
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 24px 16px;
  }
`;

export default AsnCollapse;

import { COLORS } from '../../constants';

const { BLACK, GREY_50 } = COLORS;

export const antTheme = {
  components: {
    Button: {
      colorPrimaryActive: BLACK,
      colorPrimary: BLACK,
      colorPrimaryHover: GREY_50,
      borderRadius: 24,
      fontWeight: 600,
      contentFontSize: 12,
    },
    Collapse: {
      colorFillAlter: 'transparent',
    },
    Pargraph: {
      lineHeight: 1.5,
    },
  },
  token: {
    fontFamily: 'Noto Sans Georgian, sans-serif',
  },
};
